import { Col, Divider, Row, Tag } from 'antd'
import styles from './Home.module.scss'
import ProjectItem from './ProjectItem'

const projects = [
  {
    title: 'Fund PWA',
    desc: 'A web app imitating Ant Fortune',
    link: 'https://github.com/aijun-li/fund.pwa_FE',
    skill: 'React'
  },
  {
    title: 'Virtual Platform',
    desc: 'A platform designed for testing virtually',
    link: 'https://stock.bytecask.com',
    skill: 'Angular'
  },
  {
    title: 'Research Portal',
    desc: 'Official website for USC CSSE',
    link: 'http://csse.usc.edu',
    skill: 'VanillaJS'
  },
  {
    title: 'Find More',
    desc: '......',
    link: 'https://github.com/aijun-li',
    skill: '...'
  }
]

const skills = [
  'JavaScript',
  'TypeScript',
  'Java',
  'ES6',
  'React',
  'Vue',
  '...'
]

function Home() {
  return (
    <div className={styles.home}>
      <div className={styles.icons}>
        <a href="https://github.com/aijun-li" target="_blank" rel="noreferrer">
          <span className="iconfont icon-github"></span>
        </a>
        <a href="https://leetcode.com/Mapoos/" target="_blank" rel="noreferrer">
          <span className="iconfont icon-leetcode"></span>
        </a>
        <a
          href="https://www.cnblogs.com/mapoos/"
          target="_blank"
          rel="noreferrer"
        >
          <span className="iconfont icon-bokeyuan"></span>
        </a>
        <a href="wechat.png" target="_blank" rel="noreferrer">
          <span className="iconfont icon-wechat"></span>
        </a>
      </div>

      <Divider className={styles.title}>技能 / Skills</Divider>

      <Row justify="center" gutter={[0, 10]}>
        <Col xs="24">
          {skills.map((skill, index) => {
            if (index >= 3) return null
            return (
              <Tag style={{ color: '#888888', fontWeight: 'bold' }}>
                {skill}
              </Tag>
            )
          })}
        </Col>
        <Col xs="24">
          {skills.map((skill, index) => {
            if (index < 3) return null
            return (
              <Tag style={{ color: '#888888', fontWeight: 'bold' }}>
                {skill}
              </Tag>
            )
          })}
        </Col>
      </Row>

      <Divider className={styles.title}>个人项目 / Projects</Divider>

      <div style={{ flex: window.innerWidth < 992 ? 3 : 2 }}>
        <Row gutter={[20, 15]}>
          {projects.map((proj, index) => {
            return (
              <Col xs={24} lg={12} key={index}>
                <ProjectItem project={proj}></ProjectItem>
              </Col>
            )
          })}
        </Row>
      </div>

      <footer className={styles.beian}>
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
          苏ICP备2021007975号-1
        </a>
      </footer>
    </div>
  )
}

export default Home
