import { Card } from 'antd'
import { TagsOutlined } from '@ant-design/icons'

const { Meta } = Card

function ProjectItem(props: any) {
  const { title, desc, link, skill } = props.project

  const description = (
    <div>
      <div>{desc}</div>
      <div style={{ fontSize: '0.8rem', marginTop: '10px' }}>
        <TagsOutlined />
        &nbsp;
        {skill}
      </div>
    </div>
  )

  return (
    <Card
      hoverable={true}
      onClick={() => window.open(link)}
      style={{ height: '100%' }}
    >
      <Meta title={title} description={description}></Meta>
    </Card>
  )
}

export default ProjectItem
